import React from "react";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo";
import Phone from "../components/phone";

const IndexPage = () => (
	<Layout>
		<SEO title="Rider44" />

		<div className="grid">
			<div className="left">
				<div className="container">
					<Logo />
					<section>
						<h2>What?</h2>
						<p>It’s an activity tracker for the electric era of skateboards, scooters, bikes, humans, robots...</p>
					</section>
					<section>
						<h2>Why?</h2>
						<p>Short answer: It’s fucking cool!<br/>Longish answer: There's no similar tracker dedicated to all new electric rides. How could you brag about it?!</p>
					</section>
					<section>
						<h2>How?</h2>
						<p>Our plan is to evolve with a bunch of new features to help you fast tracking your performance regardless of which electric transport you’re taking.</p>
						<p>Have an idea or work for an e-Company? Please get in touch!<br/>
						Have a fuel based thing? Don’t dare to use this app!</p>
					</section>
				</div>
			</div>

			<div className="centre">
				<div className="desktop"></div>
				<div className="darker desktop"></div>
				<Phone />
			</div>

			<div className="right darker">
				<div>
					<a href="https://apps.apple.com/app/id1464939652" alt="Rider 44 iOS App">
						<figure>
							<img src="/static/app-store.svg" alt="Download on the App Store" width="150" />
						</figure>
					</a>
					<a href="https://play.google.com/store/apps/details?id=app.rytter" alt="Rider 44 Android App">
						<figure>
							<img src="/static/google-play.svg" alt="Download on the App Store" width="150" />
						</figure>
					</a>
					
					<div className="contact"><a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#104;&#101;&#121;&#64;&#114;&#105;&#100;&#101;&#114;&#52;&#52;&#46;&#99;&#111;&#109;">&#104;&#101;&#121;&#64;&#114;&#105;&#100;&#101;&#114;&#52;&#52;&#46;&#99;&#111;&#109;</a></div>
					<footer>Rider44 is handcrafted in London/UK <br className="mobile" />by a human pretending to be a robot.</footer>

				</div>
			</div>

		</div>
	</Layout>
)

export default IndexPage
