//lottie-web

import React, {PureComponent} from "react";
import lottie from 'lottie-web';
import json from '../images/rider44.json';
import "./logo.css";

export default class Logo extends PureComponent {

	constructor(props) {
		super(props);
		this.lottieRef = React.createRef();
	}

	componentDidMount(){
		const ani = lottie.loadAnimation({
			container: this.lottieRef.current,
			renderer: 'svg',
			loop: false,
			autoplay: false,
			animationData: json,
			rendererSettings: {
				//context: canvasContext,
				scaleMode: 'noScale',
				//clearCanvas: false,
				//progressiveLoad: false,
				//hideOnTransparent: true
			}
		});

		//ani.setSpeed(0.90); 
		ani.play();
	}

	render() {
		
		return (
			<div className="logo">
				<div className="logo-lottie" ref={this.lottieRef}></div>
			</div>
		)
	}
}