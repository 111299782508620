import React, { useState, useEffect } from "react";
import { window, } from "browser-monads";
import './phone.css';

const getSize = () => {
	//const w = window.innerWidth;
	const w = window.innerWidth;
	const h = window.innerHeight;

	let phoneH = h > 850 ? 812 : h - 50;
	let phoneW = h > 850 ? 390 : Math.round(phoneH * 0.480295);

	if(w < 1025){
		phoneW = Math.min(390, w - 20);
		phoneH = Math.round(phoneW * 2.08);
	}
	
	return {width: phoneW, height: phoneH};
}

const Phone = (props) => {
	const size = getSize();
	const holder = React.createRef();
	const [width, setWidth] = useState(size.width);
	const [height, setHeight] = useState(size.height);

	useEffect(() => {
		const size = getSize();
		const width = size.width;
		const height = size.height;
		setWidth(width);
		setHeight(height);
	}, []);

	return(
		<div ref={holder} className="phone" style={{width, height}}>
			<div className="phone-outter">
				<div className="phone-power"></div>
				<div className="phone-inner">
					<video controls playsInline loop autoPlay muted>
						<source src="/static/rider44-webvideo.mp4" type="video/mp4"/>
					</video>
				</div>
			</div>
		</div>
	)
}


export default Phone;